import * as React from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

interface Props extends React.HTMLProps<HTMLInputElement> {
  className?: string;
  minLength?: number;
  placeholder?: string;
  required?: boolean;
  type?: string;
  onKeyDown?: ( event: React.KeyboardEvent<HTMLInputElement> ) => void;
}

export const FormStateInput = React.forwardRef<HTMLInputElement, Props>(
  ({ className, minLength, placeholder, required, type, onKeyDown, ...attrs }, ref ) => (
    <input
      className={cls( styles.input, 'form-control p-3 rounded', className )}
      minLength={minLength}
      placeholder={placeholder}
      ref={ref}
      required={required}
      type={type}
      onKeyDown={onKeyDown}
      {...attrs}
    />
  )
);
